import axios from "@/api/axios";
import _ from "lodash";
import qs from "qs";

// 피팅 리스트
const get_fittinglist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/fittinglist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
      pagesize: param.pagesize,
      goodstype: param.goodstype,
      findex: param.findex,
      brandno: param.brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittinglist", err);
    result(false, err);
  }
};

// 피팅 스윙 리스트
const get_fittingswinglist = async (fittingno, param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/fittingswinglist/" + fittingno,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittingswinglist", err);
    result(false, err);
  }
};

// 피팅 스윙 리스트
const get_fittingswingview = async (fittingno, fit_binary_pk, result) => {
  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/fitting/fittingswingview/" +
      fittingno +
      "/" +
      fit_binary_pk,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittingswingview", err);
    result(false, err);
  }
};
// 피팅 맴버 정보
const get_fittingmemberview = async (memberno, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/fittingmemberview/" + memberno,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittingmemberview", err);
    result(false, err);
  }
};

// 광고리스트
const get_adlist = async (adtype, goodstype, title, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/ad/adlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},

    params: {
      adtype: adtype < 3 ? adtype : 3,
      goodstype: goodstype,
      title: title,
      pagesize: 9999,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_adlist", err);
    result(false, err);
  }
};

// 제품 리스트
const get_goodslist = async (goodstype, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/goodslist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      goodstype: goodstype,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_goodslist", err);
    result(false, err);
  }
};

// 그립 샤프트 리스트
const get_goodssublist = async (goodsno, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/goodssublist/" + goodsno,
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_goodslist", err);
    result(false, err);
  }
};
// 클럽타입 리스트
const get_clubtypelist = async (result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/clubtypelist/",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_clubtypelist", err);
    result(false, err);
  }
};

// 답변결과 리스트
const get_ansresultlist = async (ansresult, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/ansresultlist/",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      goodstype: ansresult.goodstype,
      findex: ansresult.findex,
      pageno: ansresult.pageno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_ansresultlist", err);
    result(false, err);
  }
};

// 답변결과 리스트
const get_ansresultview = async (ansresult, result) => {
  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/fitting/ansresultview/" +
      ansresult.fit_req_pk,
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_ansresultlist", err);
    result(false, err);
  }
};

// 피팅결과 리스트
const get_fitresultlist = async (result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/fitresultlist/",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_clubtypelist", err);
    result(false, err);
  }
};

const get_analysisresultlist = async (fit_type, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/analysisresultlist/",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      fit_type: fit_type,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_clubtypelist", err);
    result(false, err);
  }
};

const get_fittinganswerview = async (fitreqno, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/fitting/fittinganswerview/" + fitreqno,
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittinganswerview", err);
    result(false, err);
  }
};

const post_answerwrite = async (form, fitreqno, result) => {
  console.log("form", form);
  const data = new FormData();
  console.log("form[resultfilename]", typeof form["resultfilename"]);
  for (let key in form) {
    if (typeof form[key] === "object") {
      if (Array.isArray(form[key])) {
        form[key].forEach((v) => data.append(key + "[]", v));
      } else {
        if (key == "resultfilename") data.append(key, form[key]);
        else data.append(key, JSON.stringify(form[key]));
      }
    } else {
      data.append(key, form[key]);
    }
  }

  const options = {
    method: "POST",
    url: "/fitting/fittinganswerwrite/" + fitreqno,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const post_fitresulttitlewrite = async (result_pk, result_title, result) => {
  const data = new FormData();

  data.append("result_title", result_title);

  const options = {
    method: "POST",
    url: "/fitting/fitresulttitlewrite/" + result_pk,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const post_analysisresulttitlewrite = async (
  result_pk,
  result_title,
  analysis_type,
  result
) => {
  const data = new FormData();

  data.append("result_title", result_title);
  data.append("analysis_type", analysis_type);

  const options = {
    method: "POST",
    url: "/fitting/analysisresulttitlewrite/" + result_pk,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const delete_ansresultdelete = async (fit_req_pk, result) => {
  const options = {
    method: "DELETE",
    url: "/postact/ansresultdelete/" + fit_req_pk,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

export default {
  get_fittinglist,
  get_fittingswinglist,
  get_fittingswingview,
  get_fittingmemberview,
  get_adlist,
  get_goodslist,
  get_goodssublist,
  get_clubtypelist,
  get_ansresultlist,
  get_fitresultlist,
  get_analysisresultlist,
  get_ansresultview,
  get_fittinganswerview,
  post_answerwrite,
  post_fitresulttitlewrite,
  post_analysisresulttitlewrite,
  delete_ansresultdelete,
};
