import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";

// 고객지원 리스트
const get_custormerlist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    custormerlistcnt: 0,
    custormerlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/customer/custormerlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
      fit_as_category: param.category,
      findex: param.findex,
      pagesize: param.pagesize,
      brandno: param.brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_custormerlist", err);
    result(false, err);
  }
};

// 고객지원보기
const get_custormerview = async (noticeno, searchdata, brandno, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/customer/custormerview/" + noticeno,
    params: {
      searchtype: searchdata.searchtype,
      searchname: searchdata.searchname,
      fit_as_category: searchdata.category,
      brandno: brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_custormerview", err);
    result(false, err);
  }
};

const get_custormeranswerview = async (noticeno, searchdata, result) => {
  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL + "/customer/custormeranswerview/" + noticeno,
    params: {
      searchtype: searchdata.searchtype,
      searchname: searchdata.searchname,
      fit_as_category: searchdata.category,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_custormerview", err);
    result(false, err);
  }
};

const post_custormerwrite = async (form, noticeno, result) => {
  const data = new FormData();

  for (let key in form) {
    data.append(key, form[key]);
  }

  const options = {
    method: "POST",
    url: "/customer/custormerwrite/" + noticeno,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const post_custormeranswerwrite = async (form, noticeno, result) => {
  const data = new FormData();

  for (let key in form) {
    data.append(key, form[key]);
  }

  const options = {
    method: "POST",
    url: "/customer/custormeranswerwrite/" + noticeno,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const delete_custormerdelete = async (noticeno, result) => {
  const options = {
    method: "DELETE",
    url: "/postact/custormerdelete/" + noticeno,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const delete_custormerfile = async (noticeno, type, result) => {
  const options = {
    method: "DELETE",
    url: "/postact/custormerfile/" + noticeno + "/" + type,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

export default {
  get_custormerlist,
  get_custormerview,
  get_custormeranswerview,
  post_custormerwrite,
  post_custormeranswerwrite,
  delete_custormerdelete,
  delete_custormerfile,
};
