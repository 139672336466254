import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";
import qs from "qs";

// 브랜드 리스트
const get_brandlist = async (result) => {
  let finalData = {
    code: "",
    message: "",
    brandlistcnt: 0,    
    brandlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/brand/brandlist",
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_brandlist", err);
    result(false, err);
  }
};

// 어드민 브랜드 리스트
const get_adminbrandlist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    brandlisttcnt: 0,
    brandlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/brand/adminbrandlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_adminbrandlist", err);
    result(false, err);
  }  
};

// 브랜드정보보기
const get_adminbrandview = async (brandno, result) => {
  let finalData = {
    code: 200,
    message: "Ok",
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/brand/adminbrandview/" + brandno,
    headers: { Authorization: "Bearer " + token },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_adminbrandview", err);
    result(false, err);
  }
};

const post_adminbrandwrite = async (form, brandno, result) => {
  const data = new FormData();

  for (let key in form) {
    data.append(key, form[key]);
  }

  const options = {
    method: "POST",
    url: "/brand/adminbrandwrite/" + brandno,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

export default {
  get_brandlist,
  get_adminbrandlist,
  get_adminbrandview,
  post_adminbrandwrite,
};
