<template>
    <router-view />
</template>

<script>
export default {
  name: "BrandMain",
};
</script>
<style></style>
