import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";
import qs from "qs";

// 제품 리스트
const get_goodslist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 20,
    findex: "G.reg_date",
    brandno: "",
    goodstype: 0,
    goodslist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/goodslist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      findex: param.findex,
      pagesize: param.pagesize,
      pageno: param.pageno,
      goodstype: param.goodstype,
      brandno: param.brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_goodslist", err);
    result(false, err);
  }
};

// 제품 보기
const get_goodsview = async (goodsno, searchdata, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/goodsview/" + goodsno,
    params: {
      searchtype: searchdata.searchtype,
      searchname: searchdata.searchname,
      goodstype: searchdata.goodstype,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_goodsview", err);
    result(false, err);
  }
};

// 클럽타입 리스트
const get_clubtypelist = async (clubtype, result) => {
  let finalData = {
    code: "",
    message: "",
    // pageno: 1,
    // pagesize: 10,
    clubnotypelist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/clubtypelist/" + clubtype,
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    // params: {
    //   searchtype: param.searchtype,
    //   searchname: param.searchname,
    //   pageno: param.pageno,
    // },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_clubtypelist", err);
    result(false, err);
  }
};

// 샤프트 리스트
const get_goodsshaftlist = async (result) => {
  let finalData = {
    code: "",
    message: "",
    // pageno: 1,
    // pagesize: 10,
    shaftlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/goodsshaftlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    // params: {
    //   searchtype: param.searchtype,
    //   searchname: param.searchname,
    //   pageno: param.pageno,
    // },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_goodsshaftlist", err);
    result(false, err);
  }
};

// 샤프트보기
const get_goodsshaftview = async (shaftno, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/goodsshaftview/" + shaftno,
    // params: {
    //   searchtype: searchdata.searchtype,
    //   searchname: searchdata.searchname,
    // },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_goodsshaftview", err);
    result(false, err);
  }
};

// 그립 리스트
const get_goodsgriplist = async (result) => {
  let finalData = {
    code: "",
    message: "",
    // pageno: 1,
    // pagesize: 10,
    griplist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/goodsgriplist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    // params: {
    //   searchtype: param.searchtype,
    //   searchname: param.searchname,
    //   pageno: param.pageno,
    // },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_goodsgriplist", err);
    result(false, err);
  }
};

// 그립 보기
const get_goodsgripview = async (gripno, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/goodsgripview/" + gripno,
    // params: {
    //   searchtype: searchdata.searchtype,
    //   searchname: searchdata.searchname,
    // },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_goodsgripview", err);
    result(false, err);
  }
};

// 제품 등록
const post_goodswrite = async (form, goodsno, result) => {
  console.log(form);
  const data = new FormData();

  for (let key in form) {
    if (Array.isArray(form[key])) {
      form[key].forEach((v) => data.append(key + "[]", v));
    } else {
      data.append(key, form[key]);
    }
  }

  const options = {
    method: "POST",
    url: "/goods/goodswrite/" + goodsno,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };

  try {
    await axios(options).then((response) => {
      // result(true, response.data)
      // alert(response.data);
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        // console.error(response.data.code, response.data.message)
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("post_goodswrite", err);
    result(false, err);
  }
};

// 샤프트 리스트
const get_shaftlist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 20,
    findex: "reg_date",
    shaftlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/shaftlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      findex: param.findex,
      pagesize: param.pagesize,
      pageno: param.pageno,
      brandno: param.brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_shaftlist", err);
    result(false, err);
  }
};

// 샤프트보기
const get_shaftview = async (shaftno, searchdata, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/shaftview/" + shaftno,
    params: {
      searchtype: searchdata.searchtype,
      searchname: searchdata.searchname,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_shaftview", err);
    result(false, err);
  }
};

const post_shaftwrite = async (form, shaftno, result) => {
  console.log(form);
  const data = new FormData();

  for (let key in form) {
    if (Array.isArray(form[key])) {
      form[key].forEach((v) => data.append(key + "[]", v));
    } else {
      data.append(key, form[key]);
    }
  }

  const options = {
    method: "POST",
    url: "/goods/shaftwrite/" + shaftno,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };

  try {
    await axios(options).then((response) => {
      // result(true, response.data)
      // alert(response.data);
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        // console.error(response.data.code, response.data.message)
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("post_shaftwrite", err);
    result(false, err);
  }
};

// 그립 리스트
const get_griplist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 20,
    findex: "reg_date",
    griplist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/griplist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      findex: param.findex,
      pagesize: param.pagesize,
      pageno: param.pageno,
      brandno: param.brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_griplist", err);
    result(false, err);
  }
};

// 그립 보기
const get_gripview = async (gripno, searchdata, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/goods/gripview/" + gripno,
    params: {
      searchtype: searchdata.searchtype,
      searchname: searchdata.searchname,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_gripview", err);
    result(false, err);
  }
};

const post_gripwrite = async (form, gripno, result) => {
  console.log(form);
  const data = new FormData();

  for (let key in form) {
    if (Array.isArray(form[key])) {
      form[key].forEach((v) => data.append(key + "[]", v));
    } else {
      data.append(key, form[key]);
    }
  }

  const options = {
    method: "POST",
    url: "/goods/gripwrite/" + gripno,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const delete_goods = async (goodsno, result) => {
  const options = {
    method: "DELETE",
    url: "/postact/goods/" + goodsno,
    // headers: { "Content-Type": "multipart/form-data" },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const delete_shaft = async (shaftno, result) => {
  const options = {
    method: "DELETE",
    url: "/postact/shaft/" + shaftno,
    // headers: { "Content-Type": "multipart/form-data" },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const delete_grip = async (gripno, result) => {
  const options = {
    method: "DELETE",
    url: "/postact/goods/" + gripno,
    // headers: { "Content-Type": "multipart/form-data" },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

export default {
  get_goodslist,
  get_goodsview,
  get_clubtypelist,
  get_goodsshaftlist,
  get_goodsshaftview,
  get_goodsgriplist,
  get_goodsgripview,
  post_goodswrite,
  get_shaftlist,
  get_shaftview,
  post_shaftwrite,
  get_griplist,
  get_gripview,
  post_gripwrite,
  delete_goods,
  delete_shaft,
  delete_grip,
};
