import axios from "@/api/axios";
import _ from "lodash";
import qs from "qs";

// 메인 통계
const get_statslist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/statslist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_statslist", err);
    result(false, err);
  }
};

//총 매출
const get_salesumlist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/salesumlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_statslist", err);
    result(false, err);
  }
};

//클럽별 매출
const get_saleclublist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/saleclublist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_statslist", err);
    result(false, err);
  }
};

//모델별 매출
const get_salegoodslist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/salegoodslist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_statslist", err);
    result(false, err);
  }
};

//매장별 매출
const get_saleshoplist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/saleshoplist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_saleshoplist", err);
    result(false, err);
  }
};
//피팅 구매
const get_fittinglist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/fittinglist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittinglist", err);
    result(false, err);
  }
};
//고급 보급
const get_goodslist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/goodslist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittinglist", err);
    result(false, err);
  }
};
//피팅 매장별
const get_shoplist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/shoplist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittinglist", err);
    result(false, err);
  }
};
//회원 성별 회원
const get_memberlist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/memberlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_fittinglist", err);
    result(false, err);
  }
};
//회원 성별
const get_membergenderlist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/membergenderlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("membergenderlist", err);
    result(false, err);
  }
};

//회원 연령별
const get_memberagelist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/memberagelist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("membergenderlist", err);
    result(false, err);
  }
};
//회원 핸디캡
const get_memberhandicaplist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/memberhandicaplist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("memberhandicaplist", err);
    result(false, err);
  }
};

//회원 vip 매출
const get_memberviplist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/memberviplist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_saleshoplist", err);
    result(false, err);
  }
};

//회원 매장별 가입수
const get_membershoplist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/stats/membershoplist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_saleshoplist", err);
    result(false, err);
  }
};

export default {
  get_statslist,
  get_salesumlist,
  get_saleclublist,
  get_salegoodslist,
  get_saleshoplist,
  get_fittinglist,
  get_goodslist,
  get_shoplist,
  get_memberlist,
  get_membergenderlist,
  get_memberagelist,
  get_memberhandicaplist,
  get_memberviplist,
  get_membershoplist,
};
