<template>
  <router-view />
</template>

<script>
export default {
  name: "SaleMain",
  created() {
    console.log(this.$dayjs().second());
  },
};
</script>

<style></style>
