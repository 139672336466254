import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";
import qs from "qs";

// 브랜드정보보기
const get_brandview = async (result) => {
  let finalData = {
    code: 200,
    message: "Ok",
  };
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/account/brandview",
    headers: { Authorization: "Bearer " + token },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_brandview", err);
    result(false, err);
  }
};

// 비밀번호재확인
const post_brandpwdcheck = async (password, result) => {
  const token = sessionStorage.getItem("token") || null;

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/account/checkpasswd",
    headers: { Authorization: "Bearer " + token },
    data: qs.stringify({
      password: password,
    }),
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.code, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.code, response.data.message);
      }
    });
  } catch (err) {
    console.error("post_brandpwdcheck", err);
    result(false, 400, err);
  }
};

const post_brandwrite = async (form, result) => {
  const data = new FormData();

  for (let key in form) {
    data.append(key, form[key]);
  }

  const options = {
    method: "POST",
    url: "/account/brandwrite",
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

export default {
  get_brandview,
  post_brandpwdcheck,
  post_brandwrite,
};
