import axios from "@/api/axios";
import _ from "lodash";
import qs from "qs";

// 판매 리스트
const get_salelist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/sale/salelist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      pageno: param.pageno,
      goodstype: param.goodstype,
      findex: param.findex,
      pagesize: param.pagesize,
      brandno: param.brandno,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_salelist", err);
    result(false, err);
  }
};

// 판매보기
const get_saleview = async (fit_ans_pk, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/sale/saleview/" + fit_ans_pk,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_saleview", err);
    result(false, err);
  }
};

const get_custormeranswerview = async (noticeno, searchdata, result) => {
  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL + "/customer/custormeranswerview/" + noticeno,
    params: {
      searchtype: searchdata.searchtype,
      searchname: searchdata.searchname,
      fit_as_category: searchdata.category,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_custormerview", err);
    result(false, err);
  }
};

const get_delivery = async (fittingno, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/sale/delivery/" + fittingno,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_delivery", err);
    result(false, err);
  }
};

const post_custormerwrite = async (form, noticeno, result) => {
  const data = new FormData();

  for (let key in form) {
    data.append(key, form[key]);
  }

  const options = {
    method: "POST",
    url: "/customer/custormerwrite/" + noticeno,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const post_custormeranswerwrite = async (form, noticeno, result) => {
  const data = new FormData();

  for (let key in form) {
    data.append(key, form[key]);
  }

  const options = {
    method: "POST",
    url: "/customer/custormeranswerwrite/" + noticeno,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

const post_deliverywrite = async (info, result) => {
  console.log(info);
  const options = {
    method: "POST",
    url: "/sale/saleshippingwrite/" + info.fit_req_pk,
    data: qs.stringify({
      shippingcomno: info.ship_no,
      shippingcode: info.ship_invoice_no,
    }),
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

export default {
  get_salelist,
  get_saleview,
  get_custormeranswerview,
  get_delivery,
  post_custormerwrite,
  post_custormeranswerwrite,
  post_deliverywrite,
};
