import axios from "@/api/axios";
// import store from "@/store";
import _ from "lodash";
// import qs from "qs";

// 광고리스트
const get_adlist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 20,
    adlist: [],
  };

  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/ad/adlist",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'}, 
  
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      adtype: param.adtype,
      goodstype: param.goodstype,
      pageno: param.pageno,        
      brandno: param.brandno,    
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_adlist", err);
    result(false, err);
  }
};



// 광고보기
const get_adview = async (adno, searchdata, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/ad/adview/" + adno,
    params: {
      searchtype: searchdata.searchtype,
      searchname: searchdata.searchname,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_adview", err);
    result(false, err);
  }
};

const post_adwrite = async (form, adno, result) => {
  console.log(form);
  
  const data = new FormData();

  for (let key in form) {
    if (Array.isArray(form[key])) {
      form[key].forEach((v) => data.append(key + "[]", v));
    } else {
      data.append(key, form[key]);
    }
  }

  const options = {
    method: "POST",
    url: "/ad/adwrite/" + adno,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    result(false, err, 400);
  }
};

export default {
 get_adlist,
 get_adview,
 post_adwrite,
};
